import React, {useState} from 'react'
import styles from './login_card.module.scss'
import {Card, TextField, Button, Checkbox, Snackbar, SnackbarContent } from '@material-ui/core'
import { createMuiTheme } from "@material-ui/core/styles";
import config from './../../config'
import { ThemeProvider } from "@material-ui/styles";
import {IoIosWarning, IoIosCheckmarkCircle} from "react-icons/io";
import {Link, navigate} from 'gatsby'
import { useSelector, useDispatch } from 'react-redux'
import Lottie from 'lottie-react-web'
import animation from './../../animations/loading-cowork2.json'
import ForgetPasswordModal from '../ForgetPasswordModal/index';


const LoginCard = () => {
	
	const dispatch = useDispatch()
	const [userData, setUserData] = useState({
		'email': '',
		'password': '',
	})
	
	const [loadingRequest, setLoadingRequest] = useState(false)
	const [remember, setRemember] = useState(false)
	const [openModalResetPassword, setOpenModalResetPassword] = useState(false)
	const [openSnack, setOpenSnack] = useState(false)
	const [errorMessage, setErrorMessage] = useState({
		'message': '',
		'color': ''
	})

	const handleSign = (event,name) => {
		setUserData({...userData, [name]: event.target.value});
	}

	const handleSubmit = async() => {
		if(!validateValues(userData)){
			setLoadingRequest(true)
			try{
				const response = await fetch(`${config.server}/getLogin`,{
					method: 'POST',
					body: JSON.stringify({
						email: userData.email,
						password: userData.password,
						remember: remember
					}),
					headers: {
						'Content-Type': 'application/json'
					}
				})
				const statusRequest = await response.json()
				
				setLoadingRequest(false)
				if(statusRequest.state == "ERROR_LOGIN"){
					setErrorMessage({'message':'Email o contraseña incorrecta.','color':'#d32f2f'})
					setOpenSnack(true)
				}else if(statusRequest.state == "SUCCESS"){
					setErrorMessage({'message':'Logeado.','color':'#43a047'})
					setOpenSnack(true)
					document.cookie = `jwtoken=${statusRequest.token}; expires= ${new Date(statusRequest.exp)}`
					dispatch({type: 'user/SET_USER_DATA', data: statusRequest.user_data})
					dispatch({type: 'user/SET_LOGGED', data: true})
					dispatch({type: 'user/SET_CONFIRM_EMAIL', data: true})
					navigate('/')
				}else if(statusRequest.state == "SUCCESS_LOGIN_EMAIL_NOT_CONFIRM"){
					setErrorMessage({'message':'Logeado papu.','color':'#43a047'})
					setOpenSnack(true)
					document.cookie = `jwtoken=${statusRequest.token}; expires= ${new Date(statusRequest.exp)}`
					dispatch({type: 'user/SET_USER_DATA', data: statusRequest.user_data})
					dispatch({type: 'user/SET_LOGGED', data: true})
					dispatch({type: 'user/SET_CONFIRM_EMAIL', data: false})
					navigate('/')
				}
			}catch(e){
				console.log(e)
			}
		}
	}

	const validateValues = (userData) => {
		let flag = false
		let valuesData = Object.values(userData)

		for(let i = 0; i < valuesData.length; i++){
			if(/^\s*$/.test(valuesData[i])){
				flag = true;
				setErrorMessage({'message':'Por favor completa el formulario.','color':'#d32f2f'});
				setOpenSnack(true);
			}
		}

		return flag
	}

	const SnackBarWrapper = ({message}) => {
		return(
			<Snackbar
				anchorOrigin={{
				  vertical: 'bottom',
				  horizontal: 'left',
				}}
				open={openSnack}
				autoHideDuration={6000}
				onClose={()=>setOpenSnack(!openSnack)}
			  >
				<SnackbarContent
					style={{backgroundColor: errorMessage.color}}
					  message={
						<span className={styles.errorMessage}>
							{
								errorMessage.color == "#d32f2f" ? (
									<IoIosWarning size={20} className={styles.iconError}/>
								): (
									errorMessage.color == "#43a047" ? (
										<IoIosCheckmarkCircle size={20} className={styles.iconError}/>
									): (
										<p>Nada</p>
									)
								)
							}
						    <span>{message}</span>
						</span>
					  }
					  onClose={()=>setOpenSnack(!openSnack)}
					  variant="error"
				/>
			</Snackbar>
		)
	}
	
	
	return(
		<Card className={styles.container}>
			<h2>Login</h2>
			<ThemeProvider theme={theme}>
				<TextField
			        label="Email"
			        className={styles.textField}
			        margin="normal"
					type="email"
			        variant="outlined"
					value={userData.email}
					onChange={(e) => handleSign(e, 'email')}
		        />
				<TextField
			        label="Contraseña"
			        className={styles.textField}
			        margin="normal"
					type="password"
					value={userData.password}
					onChange={(e) => handleSign(e, 'password')}
			        variant="outlined"
			    />
			</ThemeProvider>
			<Button onClick={handleSubmit} className={styles.buttonLogin} variant="contained">Ingresar</Button>
			<div className={styles.forgotContainer}>
				<div className={styles.checkContainer} onClick={() => setRemember(!remember)}>
					<Checkbox checked={remember} onChange={() => setRemember(!remember)} className={styles.checkbox}/> <p className={styles.rememberme}>Recordarme</p>
				</div>
				<ForgetPasswordModal visible={openModalResetPassword} closeModal={() => setOpenModalResetPassword(false)} email={userData.email}/>
				<a onClick={() => setOpenModalResetPassword(true)}>¿Olvidaste tu contraseña?</a>

			</div>
			<p className={styles.finalText}>¿No tienes una cuenta Cowork? <Link to="/signup">Crea una cuenta</Link> y podras manejar los espacios que planees alquilar.</p>
			<SnackBarWrapper message={errorMessage.message}/>
				{
					loadingRequest ? (
						<div className='loottieAnimation'>
							<Lottie
								options={{
								  animationData: animation
								}}
								style={{
									position: 'fixed',
									left: '50%',
									zIndex: 1000,
									background: 'rgba(0,0,0,0.2)',
									transform: 'translateX(-50%) translateY(-50%)',
									top: '50%'
								}}
							  />
						</div>
					): (null)
				}
		</Card>
	)
}


const theme = createMuiTheme({
	overrides: {
      MuiOutlinedInput: {
          root: {
              position: 'relative',
			  color:'#000',
              '& $notchedOutline': {
                  borderColor: 'rgba(0,0,0,0.23)',
              },
              '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                  borderColor: 'rgba(0,0,0,0.23)',
                  // Reset on touch devices, it doesn't add specificity
                  '@media (hover: none)': {
                      borderColor: 'rgba(0,0,0,0.23)',
                  },
              },
              '&$focused $notchedOutline': {
                  borderColor: '#DE892A',
                  borderWidth: 1,
              },
          },
      },
      MuiFormLabel: {
          root: {
			  color:'rgba(0,0,0,0.54)',
              '&$focused': {
                  color: '#DE892A'
              }
          }
      }}
});


export default LoginCard
