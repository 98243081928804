import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import config from '../../config';
import {TextField, Button, Snackbar, SnackbarContent } from '@material-ui/core';
import styles from './forget_password_modal.module.scss'
import {IoIosWarning, IoIosCheckmarkCircle} from "react-icons/io";



const ForgetPasswordModal = (props) => {
  const {visible, email, closeModal} = props;
  console.log("props",props)

 
  
  const [openSnack, setOpenSnack] = useState(false)

  const [infoMessage, setInfoMessage] = useState({
	'message': '',
	'color': ''
  })
  const sendResetPasswordSolicitud = async () => {
	var url_string = window.location.href
	var url = new URL(url_string);
		try {
			const response = await fetch(`${config.server}/forget_password`, {
				method: 'POST',
				headers: {
				'Content-Type': 'application/json',				
				},
				body: JSON.stringify({
				email: document.getElementById('email').value,
				url: url.origin
				}),			
			})
			const fetchData = await response.json()
			let infoFetch = fetchData.info
			if (fetchData.info === 'Se ha generado solicitud de recuperacion') {
				setOpenSnack(true)
				setTimeout(function(){closeModal(); }, 2000);
				setInfoMessage({ 'message': infoFetch, 'color': '#43a047' })		
			} else {
				setOpenSnack(true)
				setInfoMessage({ 'message': infoFetch, 'color': '#d32f2f' })
			}

		} catch (e) {
			console.log(e)
		}
		
	  }
	  
  const SnackBarWrapper = ({message}) => {
		return(
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={openSnack}
				autoHideDuration={2000}
				onClose={()=>setOpenSnack(!openSnack)}
			>
			<SnackbarContent
				style={{backgroundColor: infoMessage.color}}
				message={
				<span className={styles.infoMessage}>
					{
						infoMessage.color == "#d32f2f" ? (
							<IoIosWarning size={20} className={styles.iconError}/>
						): (
							infoMessage.color == "#43a047" ? (
								<IoIosCheckmarkCircle size={20} className={styles.iconError}/>
							): (
								<p>Nada</p>
							)
						)
					}
					<span>{message}</span>
				</span>
				}
				onClose={()=>setOpenSnack(!openSnack)}
				variant="error"
			/>
			</Snackbar>
		)
	}

  return (
	  
      <Dialog open={visible} onClose={closeModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" style={{textAlign:"center", fontWeight: "600"}}>¿Olvidaste tu contraseña?</DialogTitle>
        <DialogContent>
          <DialogContentText style={{textAlign:"justify"}}>
            Ingresa tu correo electronico y te enviaremos un enlace para reiniciar su contraseña.
          </DialogContentText>
          <TextField
            id="email"
            label="Email Address"
            type="email"
			defaultValue={email}
			variant="outlined"
			fullWidth
			style={{color: "#DE892A"}}
          />
        </DialogContent>
		<SnackBarWrapper message={infoMessage.message}/>
        <DialogActions>
          <Button onClick={closeModal} style={{color: "#DE892A"}}>
            Cancelar
          </Button>
          <Button onClick={sendResetPasswordSolicitud} style={{color: "#DE892A"}}>
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
	  
  );
}

export default ForgetPasswordModal;