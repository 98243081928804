import React from "react"
import { Router } from "@reach/router"
import Home from "../pages/home"
import Filter from "../pages/filter"
import Login from "../pages/login"
import "./styles/global.modules.scss"
import es from "date-fns/locale/es";
import 'moment/min/moment-with-locales'
//import Layout from "../components/Layout"



const App = () => {
  return (
    //<Layout>
      <Router>
        <Home path="/" component={Home} />
        <Filter path="/Filter" component={Filter} />
        <Login path="/login" component={Login}/>
      </Router>
    //</Layout>
  )
}
export default App